import Vue from 'vue'
import VueRouter from 'vue-router'
import tabbar from '@/views/tabbar'
import Error from '@/views/error'
Vue.use(VueRouter)
const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'tabbar',
      component: tabbar, // 主体
      children: [{
        path: '/',
        name: 'index',
        component: () => import('@/views/index')// 首页
      },
      {
        path: 'introduce',
        name: 'introduce',
        component: () => import('@/components/gongsi/gongsi')// 公司介绍
      },
      {
        path: 'download',
        name: 'download',
        component: () => import('@/components/download/download')// app下载
      },
      {
        path: 'platform',
        name: 'platform',
        component: () => import('@/components/pingtai/pingtai')// 平台概况
      },
      {
        path: 'relation',
        name: 'relation',
        component: () => import('@/components/lianxi/lianxi')// 联系我们
      }, 
      {
        path: 'consult',
        name: 'consult',
        component: () => import('@/components/dotai/dotai')// 资讯动态
      }, {
        path: 'consultDetails/:type(\\d+)',
        name: 'consultDetails',
        component: () => import('@/components/dotai/datadetails')// 资讯动态更多
      },
       {
        path: 'dynamicDetails/:id(\\d+)',
        name: 'dynamicDetails',
        component: () => import('@/components/dotai/dynamic')// 资讯动态详情
      },
      {
        path: '/recruit',
        name: 'recruit',
        component: () => import('@/components/zhaopin/zhaopin')// 招聘信息
      },
      {
        path: '/recruitdetail',
        name: 'recruitdetail',
        component: () => import('@/components/zhiwei/zhiwei')// 职位详情
      },
      {
        path: '*',
        component: Error
      }
      ]
    },
    ,
      {
        path: '*',
        component: Error
      }
  ]
})
router.afterEach((to, from, next) => {
  window.scrollTo(0, 0)
})
export default router
