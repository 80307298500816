<template>
  <div>
    <div class="bodytitle">
      <div class="title flexy flexsb">
        <img
          class="imglog wow lightSpeedIn"
          @click="goto({ url: '/', id: 1, name: '首页' })"
          src="../../common/image/logo.png"
          alt=""
        />
        <div class="flexy flexsb">
          <div
            @click="goto(i)"
            v-for="(i, k) in list"
            :key="k"
            :class="[
              'name flexcont wow  lightSpeedIn',
              id == i.id ? 'actvie' : '',
            ]"
          >
            {{ i.name }}
            <img
              :src="
                require(`../../common/image/${
                  flag ? 'xailatubiao' : '下拉'
                }.png`)
              "
              alt=""
              style="margin-left: 10px"
              v-if="k == 4"
            />
            <div class="xiangjiaruko" v-if="k == 4 && flag">
              <div class="flexcont online">
                <a
                  target="_blank"
                  style="color: #333333"
                  href="https://m.hqsmtech.com/hqboss-onlineshop/"
                  >线上商家入驻</a
                >
              </div>
              <div class="flexcont">
                <a
                  target="_blank"
                  style="color: #333333"
                  href="https://m.hqsmtech.com/hqboss-offlineshop/"
                  >线下商家入驻</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: '',
  data() {
    return {
      list: [
        { url: '/', id: 1, name: '首页' },
        { url: '/introduce', id: 2, name: '公司介绍' },
        { url: '/platform', id: 3, name: '平台概况' },
        { url: '/download', id: 4, name: 'APP下载' },
        { url: '/entrance', id: 5, name: '商家入口' },
        { url: '/consult', id: 6, name: '资讯动态' },
        { url: '/relation', id: 7, name: '联系我们' },],
      id: 1,//高亮下标
      flag: false//商家入口
    }
  },
  created() {
    this.titlelist()
  },

  mounted() {
  },
  watch: {
    $route() {
      this.titlelist()
    }
  },
  methods: {
    goto(e) {//跳转

      if (e.url == '/entrance') {
        this.flag = true
        this.id = e.id
      } else {
        this.flag = false
        this.id = e.id
        sessionStorage.setItem('titleid', e.id)
        if (this.$route.path != e.url) {
          this.$router.push({ path: e.url })
        }
      }
    },
    titlelist() {
      this.list.map(e => {
        if (this.$route.path == e.url || sessionStorage.getItem('titleid') == e.id) {
          this.id = e.id
          sessionStorage.setItem('titleid', e.id)
        }
      })
    }
  },
  computed: {},
}
</script>
<style lang="scss" scoped>
.bodytitle {
  box-shadow: 0px 0px 16px 0px rgba(4, 0, 0, 0.09);
  .title {
    width: 75rem;
    height: 4.375rem;
    margin: auto;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    .imglog:hover {
      cursor: pointer;
    }
    .name {
      width: 100px;
      position: relative;
      &:hover {
        cursor: pointer;
        color: #981c1c;
        font-weight: 600;
      }
      .xiangjiaruko {
        position: absolute;
        top: 35px;
        width: 100px;
        background: url("../../common/image/xiala.png");
        .online{
          padding-top: 7px;
        }
        div {
          height: 50px;
          a{
            text-decoration: none;
          }
        }
      }
    }
  }
}
.actvie {
  color: #981c1c;
  font-weight: 600;
}
</style>
