
<template>
  <div style="flex: 1" class="flexcont">
    <div class="flexcont">
      <div class="all">
        <div class="xieyi">
          <div class="img">
            <img src="@/common/image/404.png" alt />
          </div>
          <div class="p">
            <p>抱歉，您访问的页面迷路了~~</p>
          </div>
          <div class="fanhui" @click="go">
            <button class="button" @click="back">返回首页({{ count }}s)</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'error',
  data() {
    return {
      count: '5',
      timeZc: null
    }
  },
  created() {
    this.go()
  },
  beforeDestroy() {
    clearInterval(this.timeZc)
  },
  methods: {
    go() {
      this.timeZc = setInterval(() => {
        this.count--
        if (this.count === 0) {
          clearInterval(this.timeZc)
          if (this.$route.path != '/') {
            this.$router.push({ path: '/' })
          }
        }
      }, 1000)
    },
    back() {
      clearInterval(this.timeZc)
      this.$router.push({ path: '/' })
    },

  },
}
</script>
<style lang="scss" scoped>
.all {
  .xieyi {
    width: 410px;
    height: 360px;
    .img {
      width: 410px;
      height: 184px;
      margin: 0 auto;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .p {
      text-align: center;
      height: 104px;
      line-height: 104px;
      font-family: Microsoft YaHei;
      font-weight: 400;

      color: rgba(111, 123, 187, 0.9);
      p {
        font-size: 24px;
      }
    }
    .fanhui {
      text-align: center;
      .button {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: rgba(255, 255, 255, 1);
        cursor: pointer;
        width: 180px;
        height: 40px;
        background: #404698;
        border-radius: 20px;
        text-align: center;
        line-height: 40px;
        border: none;
        outline: none;
      }
    }
  }
}
</style>
