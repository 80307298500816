<template>
  <div>
    <div class="all" v-if="flag">
      <div class="arrowTip" @click="toTop" ref="cc">
        <i class="jiantou iconfont icon-zhiding"></i>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'wechat',
  data() {
    return {
      flag: false
    }
  },
  mounted() {
    window.addEventListener('scroll', this.scrollToTop)
  },
  destroyed() {
    window.removeEventListener('scroll', this.scrollToTop)
  },

  methods: {

    // 点击图片回到顶部方法，加计时器是为了过渡顺滑
    toTop() {
      const that = this
      let timer = setInterval(() => {
        let ispeed = Math.floor(-that.scrollTop / 5)
        document.documentElement.scrollTop = document.body.scrollTop = that.scrollTop + ispeed
        if (that.scrollTop === 0) {
          clearInterval(timer)
        }
      }, 16)
    },
    // 为了计算距离顶部的高度，当高度大于60显示回顶部图标，小于200则隐藏
    scrollToTop() {
      this.scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      if (this.scrollTop > 80) {
        this.flag = true
      } else {
        this.flag = false
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.all {
  position: fixed;
  right: 5%;
  bottom: 24%;
  z-index: 999999;
  .arrowTip {
    width: 45px;
    height: 45px;
    border-radius: 6px;
    background: #ffffff;
    box-shadow: 0px 0px 16px 0px rgba(4, 0, 0, 0.09);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color:  rgba(4, 0, 0, 0.7);
    &:hover {
      background: #981c1c;
      i {
        color: #ffffff;
      }
    }
  }
}
</style>
