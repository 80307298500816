<template>
  <div style="display: contents" v-if="bottom" class="bot">
    <div class="bottom">
      <div class="bottom-main flexsb">
        <div class="left">
          <img src="../../common/image/logogggg.png" alt="" /><br />
          <a href=""
            >Copyright ©2017-2022 华麒商盟前海(深圳)科技有限公司. All rights
            reserved.</a
          >
        </div>
        <div class="right">
          <img src="../../common/image/gzh.png" alt="" />
          <p class="title">华麒商盟公众号</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "",
  data() {
    return {};
  },
  create() {},
  wacth: {},
  methods: {},
  computed: {
    ...mapState(["bottom"]),
  },
};
</script>
<style lang="scss" scoped>
.bottom {
  min-height: 162px;
  min-width: 1200px;
  background-color: #000000;
  .bottom-main {
    width: 1200px;
    margin: 0 auto;
    .left {
      padding-top: 38px;
      img{
        margin-bottom: 19px;
      }
      a {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #d6d6d6;
        text-decoration: none;
      }
    }
    .right {
      padding-top: 26px;
      text-align: center;
      .title {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #fffefe;
        margin-top: 10px;
      }
    }
  }
}
</style>
