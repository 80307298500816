import {
    WOW
} from 'wowjs'
const common = {
    // 滑动动画
    wow() {
        const wow = new WOW({
            boxClass: 'wow',
            mobile: true,
            live: true
        })
        wow.init()
    }
}

export default common