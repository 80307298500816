import axios from 'axios'
import { Message } from 'element-ui'
// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  timeout: 10000 // request timeout
})
// request interceptor
service.interceptors.request.use(
  async config => {
    config.headers = {
      'Accept':'application/json',
      'content-type' : 'application/json'
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  response => {
    const res = response.data
    if (res.code != 0) {
      Message.closeAll()
      Message({
        message: res.msg || 'API 错误',
        type: 'error',
        duration: 2 * 1000
      })
      return Promise.reject(new Error(res.msg || 'API 错误'))
    } else {
      return res
    }
  },
  error => {
    switch (error.response&&error.response.status) {
      case 401:
        // location.reload()
        break
      default:
        Message.closeAll()
        Message({
          message: new Error(error.msg || 'API 错误'),
          type: 'error',
          duration: 2 * 1000
        })
        break
    }
    return Promise.reject(error)
  }
)

export default service
