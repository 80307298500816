<template>
  <div class="flex bodyflex">
    <!-- 导航栏 -->
    <div class="dingwei">
      <navigation/>
    </div>
    <!-- 主题 -->
    <div style="flex: 1" class="flex">
      <router-view />
    </div>
    <!-- 置顶按钮 -->
    <zhiding />
    <!-- 底部导航栏 -->
    <bottom />
  </div>
</template>
<script>
import navigation from "@/views/navigation/navigation.vue"
import bottom from "@/views/bottom/bottom.vue"
import zhiding from "@/components/zhiding/zhiding.vue"
export default {
  name: 'tabbar',
  components: { navigation, bottom, zhiding },
  data() {
    return {
    }
  },
  mounted() {
    this.common.wow()
   },
  wacth: {},
  computed: {},
  methods: {},
}
</script>
<style lang="scss" scoped>
.dingwei {
  position: sticky;
  top: 0px;
  z-index: 10;
  background: #ffffff;
}
.bodyflex {
  min-height: 100vh;
  flex-direction: column;
}
</style>
