import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from '@/store'
import ElementUI from 'element-ui'
import VueCookies from 'vue-cookies'
import 'element-ui/lib/theme-chalk/display.css'
import 'element-ui/lib/theme-chalk/index.css'
import animate from 'animate.css'
import '@/common/css/lxh.scss'
import '@/common/css/iconfont.scss'
import common from '@/utils/common'
import api from '@/api/index.js'
import * as vueJsonp from 'vue-jsonp'
Vue.use(vueJsonp)
router.afterEach((to, from, next) => { //
  window.scrollTo(0, 0)
})
import BaiduMap from 'vue-baidu-map'
Vue.use(BaiduMap, {
  ak: 'QloaSK9ZmiSKxgnAxE6WeWumA9azWqK0'
})
Vue.config.productionTip = false
Vue.prototype.common = common
Vue.prototype.api = api
Vue.use(animate)
Vue.use(ElementUI)
Vue.use(VueCookies)
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
