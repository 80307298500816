import service from '@/utils/request'
const api = {
  // 关于我们
  aboutUs(params) {
    return service({
      url: `/gw/home/aboutUs`,
      method: 'GET',
      params
    })
  },

  // 资讯动态
  news(data) {
    return service({
      url: `/gw/home/news`,
      method: 'POST',
      data
    })
  },
  // 资讯动态详情
  newsDetail(params) {
    return service({
      url: `/gw/home/newsDetail`,
      method: 'GET',
      params
    })
  },
  // 招聘信息
  recruit(data) {
    return service({
      url: `/gw/home/recruit`,
      method: 'POST',
      data
    })
  },
  // 职位详情
  recruitDetail(params) {
    return service({
      url: `/gw/home/recruitDetail`,
      method: 'GET',
      params
    })
  },
// 产品体系
products(params) {
  return service({
    url: `/gw/home/products`,
    method: 'GET',
    params
  })
},

// 核心优势
advantage(params) {
  return service({
    url: `/gw/home/advantage`,
    method: 'GET',
    params
  })
},
}
export default api